import { render, staticRenderFns } from "./navbar-default.vue?vue&type=template&id=398af9f0"
import script from "./navbar-default.vue?vue&type=script&lang=js"
export * from "./navbar-default.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavbarItem: require('C:/Users/Pedro Camacaro/Documents/proyectos-2b/cata1og-us-wholesale/store-vue2-main/components/navbar/navbar-item.vue').default})
