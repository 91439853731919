
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import { formatData, calcDiscountPrice } from '../../constants/config.js';
export default {
	layout: 'only-content',
	components: {
		breadcrumbs		: () => import('../../components/pages/breadcrumbs.vue'),
		vcLoading		: () => import('../../components/pages/loading.vue'),
		formAddress		: () => import('../../components/pages/form-address.vue'),
		creditCard		: () => import('../../components/payment/credit-card.vue'),
		vcFormField		: () => import('../../components/pages/form-field.vue'),
		vcDynamicLink	: () => import('../../components/pages/dynamic-link.vue')
	},
	data() {
		return {
			product_origin				: '',
			
			products					: [],
			
			shipping_address			: null,
			update_shipping_address		: null,
			billing_address				: null,
			update_billing_address		: null,
			
			list_addresses				: [],
			
			default_address				: {
				default		: false,
				id			: 'new-address',
				first_name	: '',
				last_name	: '',
				name		: '',
				phone		: '',
				address_1	: '',
				address_2	: '',
				company		: '',
				country		: 'United States',
				country_code: 'US',
				state		: 'Alabama',
				state_code	: 'AL',
				city		: '',
				zip			: ''
			},
			action_form_address			: 'edit',
			select_shipping_address_id	: null,
			select_billing_address_id	: null,
			
			shipping_rates				: [],
			list_phone_codes			: [],
			list_countries				: [],
			list_states					: [],
			
			credit_card					: null,
			valid_credit_card			: false,
			
			select_shipping_method		: null,
			select_shipping_method_id	: null,
			
			add_balance					: false,
			use_balance					: 'not-use-balance',
			amount_balance				: 0,
			original_balance			: 0,
			available_option_pay		: false,
			
			billing_address_type		: 'different',
			
			payment_method				: 'credit-card',
			need_invoice				: true,
			
			load_checkout				: false,
			
			preorder_bundle_list		: null,
			preorder_standard_list		: null,
			
			order_note					: '',
			
			is_loading_page				: true,
			is_loading_modal			: null,
			is_loading_shipping_methods	: true,
			disabled_checkout			: false,
			error_checkout				: { valid: true },
		};
	},
	async mounted() {
		this.product_origin = this.$route.params.type;
		this.updateLoginUser( { token_login: this.$cookies.get('token-app'), data_user: this.$cookies.get('user-store'), data_customer: this.$cookies.get('customer-store'), current_route: this.$route.path } );
		
		this.$root.$on('valid-form-address'	, this.validFormAddress);
		this.$root.$on('change-credit-card'	, this.changeCreditCard);
		this.$root.$on('update-field-value'	, this.updateFieldValue);
		this.$root.$on('valid-credit-card'	, this.validCreditCard);
		
		await this.getCountries().then( async (result_countries) => {
			
			this.list_phone_codes 	= this.getterCountries.phone_codes;
			this.list_countries 	= this.getterCountries.list;
			this.list_states     	= this.getterCountries.states;
			
			if( this.product_origin.indexOf('pre-order') >= 0 && this.$route.query.pre_order && this.$route.query.type === 'complete' ){
				
				let body_request = { 
					id		: this.$route.query.pre_order, 
					customer: this.$route.query.customer, 
					type	: this.product_origin.indexOf('bundle') >= 0 ? 'bundle' : 'standard'
				};
				await this.getCompletePreorder(body_request).then( async (result_bundle) => {
					
					if( this.getterCheckoutData && this.getterCheckoutData.valid ){
						
						this.updateLoginUser( { token_login: this.getterCheckoutData.user.token_login, data_user: this.getterCheckoutData.user, data_customer: this.getterCheckoutData.customer, custom_checkout: true } );
						
						await this.configCustomerAddressesStatement();
					}
					else{
						
						this.$router.push('/pages/401');
					}
				});
			}
			else if( this.product_origin.indexOf('pre-order') >= 0 && this.$route.query.type === 'advance' ){
				
				if( this.product_origin.indexOf('bundle') >= 0 ){
					
					this.preorder_bundle_list = this.$cookies.get('pre-order-bundle');
					
					this.preorder_bundle_list = {
						current: this.preorder_bundle_list.current ? {
							variant_id: parseInt( this.preorder_bundle_list.current.split(':')[0] ),
							total_quantity: parseInt( this.preorder_bundle_list.current.split(':')[1] ),
						} : null,
						custom: ( this.preorder_bundle_list.custom || [] ).reduce((previous_item, current_item) => {
							previous_item.list.push({
								variant_id: parseInt( current_item.split(':')[0] ),
								quantity: parseInt( current_item.split(':')[1] ),
							});
							previous_item.total_quantity += parseInt( current_item.split(':')[1] );
							return previous_item;
						}, { list: [], total_quantity: 0 }),
					};
				}
				else{
					
					this.preorder_standard_list = this.$cookies.get('pre-order-standard');
					
					this.preorder_standard_list = ( this.preorder_standard_list || [] ).reduce((previous_item, current_item) => {
						previous_item.push({
							variant_id	: parseInt( current_item.split(':')[0] ),
							quantity	: parseInt( current_item.split(':')[1] ),
						});
						return previous_item;
					}, []);
				}
				let body_request = { 
					id						: this.$route.query.bundle, 
					type					: this.product_origin.indexOf('bundle') >= 0 ? 'bundle' : 'standard', 
					preorder_bundle_list	: this.preorder_bundle_list, 
					preorder_standard_list	: this.preorder_standard_list 
				};
				await this.getAdvancePreorder(body_request).then( async (result_bundle) => {
					
					if( this.getterCheckoutData ){

						this.updateLoginUser( { token_login: this.getterCheckoutData.user.token_login, data_user: this.getterCheckoutData.user, data_customer: this.getterCheckoutData.customer, custom_checkout: true } );
						
						await this.configCustomerAddressesStatement();
					}
					else{
						
						this.$router.push('/pages/401');
					}
				});
			}
			else if( this.getterLogin ){
				
				await this.getCheckoutOrder({ product_origin: this.product_origin, action: 'list-products', products: [] }).then( async (result_bundle) => {
					
					if( this.getterCheckoutData ){
						
						this.updateLoginUser( { token_login: this.getterCheckoutData.user.token_login, data_user: this.getterCheckoutData.user, data_customer: this.getterCheckoutData.customer } );
						
						await this.configCustomerAddressesStatement();
					}
					else{
						
						this.$router.push('/pages/401');
					}
				});
			}
			else {
				
				this.$router.push('/pages/401');
			}
		});
	},
	computed: {
		...mapGetters({
			getterUser				: 'getterUser',
			getterLogin				: 'getterLogin',
			getterCart				: 'getterCart',
			getterCartSaveLater		: 'getterCartSaveLater',
			getterCheckoutData		: 'getterCheckoutData',
			getterCheckoutLineItems	: 'getterCheckoutLineItems',
			getterCustomerAddresses	: 'getterCustomerAddresses',
			
			getterCustomer		: 'getterCustomer',
			getterShippingData	: 'getterShippingData',
			
			messageCoupon		: 'getterMessageCoupon',
			getterCoupon		: 'getterCoupon',
			
			getterValidCheckout	: 'getterValidCheckout',
			getterSatement		: 'getterSatement',
			
			getterStatusPay		: 'getterStatusPay',
			getterCheckoutOrder	: 'getterCheckoutOrder',
			getterNotification	: 'getterNotification',
			getterCountries		: 'getterCountries',
		}),
		getTitleBack(){
			if( this.$route.params.type === 'cart' ){
				
				return 'Cart';
			}
			else if( this.$route.params.type === 'save-later' ){
				
				return 'Cart for Later';
			}
			else if( this.$route.params.type === 'pre-order' ){
				
				return 'Pre-Orders';
			}
			else if( this.$route.params.type === 'pre-order-bundle' ){
				
				return 'Product Bundle';
			}
			else{
				
				return 'Cart';
			}
		},
		getActionBreadcrumb(){
			if( ['cart', 'save-later'].includes( this.$route.params.type ) ){
				
				return 'Create Order';
			}
			else if( ( this.$route.params.type.indexOf('pre-order') >= 0 && this.$route.query.type === 'complete' ) ){
				
				return `${ this.$route.params.type === 'pre-order-bundle' ? 'Complete:' : 'Complete: Pre-Order' } ${ (this.getterCheckoutData?.preorder?.name || '').replace('PRE-ORDER-BUNDLE-', '').replace('PLN-PRE-', '') }`;
			}
			else if( this.$route.params.type.indexOf('pre-order') >= 0 && this.$route.query.type === 'advance' ){
				
				return 'Create Pre-Order';
			}
			else{
				
				return 'Create Order';
			}
		},
		getUrlBack(){
			if( this.$route.params.type === 'cart' ){
				
				return '/account/cart';
			}
			else if( this.$route.params.type === 'save-later' ){
				
				return '/account/cart-for-later';
			}
			else if( this.$route.params.type === 'pre-order' ){
				
				return '/account/dashboard/pre-order';
			}
			else if( this.$route.params.type === 'pre-order-bundle' ){
				
				return `/products/${ this.$route.query.handle }` || '/';
			}
			else{
				
				return '/cart';
			}
		},
		getTotal() {
			
			let total = parseFloat(this.getTaxes) + ( parseFloat(this.getSelectShippingMethod?.price || 0) )  + parseFloat(this.getSubTotalOrder.subtotal) - parseFloat(this.getSubTotalOrder.coupon_discount) - ( parseFloat(this.getAmountBalance) );
			
			return total;
		},
		getTaxes(){
			
			let mountProducts 		= this.getSubTotalOrder.subtotal - this.getSubTotalOrder.coupon_discount;
			let country_taxes		= this.getterShippingData?.taxes.country ? ( parseFloat( this.getterShippingData?.taxes.country.percentage ) / 100 )	: 0;
			let state_taxes 		= this.getterShippingData?.taxes.state 	? ( parseFloat( this.getterShippingData?.taxes.state.percentage ) / 100 )		: 0;
			let state_taxes_type 	= this.getterShippingData?.taxes.state 	? this.getterShippingData?.taxes.state.type : 'normal';
			
			if( state_taxes_type === 'normal' ){
				
				return this.formatCurrency( mountProducts * ( state_taxes + country_taxes ), true ).number;
			}
			else {
				
				return this.formatCurrency( ( mountProducts * ( country_taxes === 0 ? 1 : country_taxes ) ) * ( state_taxes === 0 ? 1 : state_taxes ), true ).number;
			}
		},
		getAmountBalance(){
			return this.amount_balance;
		},
		getSubTotalOrder(){
			
			return { 
				subtotal		: this.getterCheckoutLineItems.details?.subtotal || 0, 
				total_products	: this.getterCheckoutLineItems.details?.count || 0, 
				coupon_discount	: this.getterCheckoutLineItems.details?.coupon, 
				is_percentage	: this.getterCheckoutLineItems.products.some( (item) => item.discounts.coupon.percentage.value > 0 ) || false
			};
		},
		getShippingAddress(){
			this.$root.$emit('update-country-address', this.shipping_address?.country_code);
			this.$root.$emit('update-state-address', this.shipping_address?.state_code);
			return {...this.shipping_address};
		},
		getBillingAddress(){
			this.$root.$emit('update-country-address', this.billing_address?.country_code);
			this.$root.$emit('update-state-address', this.billing_address?.state_code);
			return {...this.billing_address};
		},
		getUpdateShippingAddress(){
			return {...this.update_shipping_address};
		},
		getUpdateBillingAddress(){
			return {...this.update_billing_address};
		},
		getSelectShippingAddressId(){
			return this.select_shipping_address_id;
		},
		getSelectBillingAddressId(){
			return this.select_billing_address_id;
		},
		getBillingAddressType(){
			return this.billing_address_type;
		},
		getListAddresses(){
			return this.list_addresses;
		},
		getDataListCountries(){
			return this.list_countries;
		},
		getDataListStates(){
			return this.list_states;
		},
		getDataListPhoneCodes(){
			return this.list_phone_codes;
		},
		orderNeedPayment() {
			return this.select_shipping_method ? this.select_shipping_method.need_payment : true;
		},
		getLoadingPage(){
			return this.is_loading_page;
		},
		getLoadingModal(){
			return this.is_loading_modal;
		},
		getLoadingShippingMethods(){
			return this.is_loading_shipping_methods;
		},
		getActionFormAddress(){
			return this.action_form_address;
		},
		getSelectShippingMethod(){
			return this.select_shipping_method;
		},
		getSelectShippingMethodId(){
			return this.select_shipping_method_id;
		},
		getShippingRates(){
			return this.shipping_rates;
		},
		getOriginProducts(){
			return this.product_origin;
		},
		getValidCreditCard(){
			return this.valid_credit_card;
		},
		getAddBalance() {
			return this.add_balance;
		},
		getUseBalance() {
			return this.use_balance;
		},
		getPaymentMethod(){
			return this.payment_method;
		},
		getNeetInvoice(){
			return this.need_invoice;
		},
		getDisabledCheckout(){
			return this.disabled_checkout;
		},
		getErrorCheckout(){
			return this.error_checkout;
		}
	},
	methods: {
		// ------- VUEX ----------------
		...mapActions({
			updateLoginUser			: 'updateLoginUser',
			setValidCheckout		: 'setValidCheckout',
			saveCustomerAddress		: 'saveCustomerAddress',
			setShippingData			: 'setShippingData',
			getCountries			: 'getCountries',
			getCustomer				: 'getCustomer',
			getCheckoutOrder 		: 'getCheckoutOrder',
			getAdvancePreorder		: 'getAdvancePreorder',
			getCompletePreorder		: 'getCompletePreorder',
			getStatementByCustomer	: 'getStatementByCustomer',
			resetShippingData		: 'resetShippingData',
			addNotification			: 'addNotification',
			calculateShipping		: 'calculateShipping',
			payOrder				: 'payOrder',
			createOrder				: 'createOrder',
			getCart					: 'getCart',
		}),
		saveShippingAddress(action){
			this.is_loading_modal = true;
			setTimeout(() => {
				this.$root.$emit('valid-address', { id_form: 'update-shipping-address', action: action });
			}, 500);
		},
		saveBillingAddress(action){
			this.is_loading_modal = true;
			setTimeout(() => {
				this.$root.$emit('valid-address', { id_form: 'update-billing-address', action: action });
			}, 500);
		},
		openModalAddress( action, ref_modal ){
			this.action_form_address = action;
			this.$refs[ref_modal].show();
		},
		async updateFieldValue( data ){
			if( data.field === 'select_shipping_address'){
				
				this.shipping_address = this.list_addresses.find( (item) => item.id === data.value )?.value;
				if( this.select_shipping_address_id != this.shipping_address.id ){
					
					this.select_shipping_address_id 	= this.shipping_address.id;
					this.shipping_address.name		= `${ this.shipping_address.first_name } ${ this.shipping_address.last_name }`;
					
					this.update_shipping_address		= JSON.parse( JSON.stringify( this.shipping_address ) );
					this.is_loading_shipping_methods 	= true;
					await this.processShippingAddress();
				}
			}
			else if( data.field === 'select_billing_address'){
				
				this.billing_address = this.list_addresses.find( (item) => item.id === data.value )?.value;
				if( this.select_billing_address_id != this.billing_address.id ){
					
					this.select_billing_address_id = this.billing_address.id;
					this.billing_address.name	= `${ this.billing_address.first_name } ${ this.billing_address.last_name }`;
					this.update_billing_address	= JSON.parse( JSON.stringify( this.billing_address ) );
				}
			}
		},
		async configCustomerAddressesStatement(){
			
			this.list_addresses = this.getterCustomerAddresses.map((item) => {
				return { 
					label: `${ item.first_name } ${ item.last_name } - ${ item.address_1 }, ${ item.city }, ${ item.state }, ${ item.country }, ${ item.zip }`, 
					id: item.id, 
					value: item 
				};
			});
			if( this.getterCustomerAddresses.length > 0 ){
				
				this.shipping_address 			= this.getterCustomerAddresses.find((item) => item.default_shipping );
				
				this.shipping_address 			= this.shipping_address && !this.shipping_address.country && !this.shipping_address.state ? this.getterCustomerAddresses.find((item) => item.country && item.state) : this.shipping_address;
				this.select_shipping_address_id = this.shipping_address.id;
				
				this.billing_address 			= this.getterCustomerAddresses.find((item) => item.default_billing ) || { ...this.shipping_address };
				this.select_billing_address_id 	= this.billing_address.id;
				
				this.shipping_address.name		= `${ this.shipping_address.first_name } ${ this.shipping_address.last_name }`;
				this.billing_address.name		= `${ this.billing_address.first_name } ${ this.billing_address.last_name }`;
				this.update_shipping_address	= JSON.parse( JSON.stringify( this.shipping_address ) );
				this.update_billing_address		= JSON.parse( JSON.stringify( this.billing_address ) );
			}
			await this.getStatementByCustomer().then( async (result_statement) => {
				
				this.add_balance 		= this.getterSatement.balance > 0;
				this.original_balance 	= this.add_balance ? this.getterSatement.balance : 0;
				this.amount_balance		= 0;

				this.validLoadCheckout( this.getterSatement.balance, this.getterSatement.preApprovedCredit, this.getterSatement.expiredDays, parseFloat(this.getSubTotalOrder.subtotal) - parseFloat(this.getSubTotalOrder.coupon_discount), this.getterSatement.customer.first_order, this.getterCheckoutLineItems.details.count, this.getterSatement.customer.valid_moa );
				
				this.is_loading_page 	= null;
				
				this.$root.$emit('change-select-address', { id_form: 'update-shipping-address', address: this.shipping_address });
				this.$root.$emit('change-select-address', { id_form: 'update-billing-address', address: this.billing_address });
			});
		},
		async validFormAddress(data_form){
			
			if( data_form.valid && data_form.valid_zip_code ){
				
				if( data_form.action === 'create' ){
					data_form.address.id = null;
				}
				if( data_form.id_form === 'update-shipping-address' ){
					
					this.is_loading_shipping_methods = true;
				}
				await this.saveCustomerAddress({ address: data_form.address }).then( async (result_address) => {
					
					this.is_loading_modal = null;
					this.$refs[data_form.id_form === 'update-shipping-address' ? 'modal_shipping_address' : 'modal_billing_address'].hide();
					if( data_form.action === 'create' ){
						if( data_form.id_form === 'update-shipping-address' ){
							
							this.shipping_address = {...this.getterCustomerAddresses.find( (item) => item.new )};
							delete this.shipping_address.new;
						}
						else{
							
							this.billing_address = {...this.getterCustomerAddresses.find( (item) => item.new )};
							delete this.billing_address.new;
						}
					}
					else{
						if( data_form.id_form === 'update-shipping-address' ){
							
							this.shipping_address = {...this.getterCustomerAddresses.find( (item) => item.id === data_form.address.id )};
						}
						else{
							
							this.billing_address = {...this.getterCustomerAddresses.find( (item) => item.id === data_form.address.id )};
						}	
					}
					this.shipping_address.name	= `${ this.shipping_address.first_name } ${ this.shipping_address.last_name }`;
					this.billing_address.name	= `${ this.billing_address.first_name } ${ this.billing_address.last_name }`;
					this.list_addresses 				= this.getterCustomerAddresses.map( (item) => {
						return { 
							label: `${ item.first_name } ${ item.last_name } - ${ item.address_1 }, ${ item.city }, ${ item.state }, ${ item.country }, ${ item.zip }`, 
							id: item.id, 
							value: item 
						};
					} );
					this.select_shipping_address_id 	= this.shipping_address.id;
					this.select_billing_address_id 	= this.billing_address.id;
					this.addNotification({ 
						content: { type: 'request' }, 
						type: data_form.action === 'create' ? 'success' : 'info', 
						data: { 
							title: `Success: ${ data_form.id_form === 'update-shipping-address' ? 'Shipping' : 'Billing' } Address ${ data_form.action === 'create' ? 'Created' : 'Updated' }`, 
							message: `Selected ${ data_form.id_form === 'update-shipping-address' ? 'Shipping' : 'Billing' } Address`, 
							app_version: this.$cookies.get('app-version')
						}
					});
					if( data_form.id_form === 'update-shipping-address' ){
						
						await this.processShippingAddress();
					}
				}).catch( (error_address) => {
					
					this.is_loading_modal = null;
					if( data_form.id_form === 'update-shipping-address' ){
						
						this.is_loading_shipping_methods = null;
					}
				});
			}
			else{
				
				this.is_loading_modal = null;
				
				this.addNotification({ 
					content: { type: 'request' }, 
					type: 'error', 
					data: { 
						title: `Error: ${ data_form.id_form === 'update-shipping-address' ? 'Shipping' : 'Billing' } address fields`, 
						message: `There are fields that have not been validated${ data_form.valid_zip_code ? '' : `, Zip Code does not belong to the state <strong>${ data_form.address.state }</strong>` }`,  
						app_version: this.$cookies.get('app-version') 
					}
				});
			}
		},
		async processShippingAddress(){
			
			this.billing_address = this.billing_address_type === 'same' ? {...this.shipping_address} : this.billing_address;
			let body_request 	= {
				shipping_info	: {
					products: this.getterCheckoutLineItems.products
				},
				shipping_address: this.shipping_address,
				product_origin	: this.product_origin,
				type_checkout	: this.$route.query.type,
				is_login		: this.getterLogin,
			};
			await this.calculateShipping(body_request).then( async (result_shipping) => {
				
				if(this.getterShippingData != null){
					
					this.shipping_rates 			= this.getterShippingData.rates.map( (item) => {
						return { 
							_id			: item._id.toString(), 
							method		: item.shipping_type.name, 
							category	: item.shipping_type.category, 
							price		: item.price, 
							need_payment: item.need_payment
						};
					});
					this.select_shipping_method 	= this.shipping_rates[0] ? this.shipping_rates[0] : null;
					this.select_shipping_method_id 	= this.select_shipping_method ? this.select_shipping_method._id : null;
					await this.updateShippingMethod();
				}
				await this.changeBalancePay();
			});
			this.is_loading_shipping_methods = null;
		},
		async updateShippingMethod(){
			
			this.select_shipping_method = this.shipping_rates.find( (item) => item._id === this.select_shipping_method_id );
			this.billing_address_type 	= this.select_shipping_method?.category != 'third-party' ? 'same' : 'different';
			this.need_invoice 			= this.select_shipping_method?.category != 'third-party';
			if( this.billing_address_type === 'same' ){
				
				this.billing_address = {...this.shipping_address};
			}
			else{
				
				this.billing_address = this.getterCustomerAddresses.find( (item) => item.default_billing ) || { ...this.billing_address };
			}
			await this.changeBalancePay();
		},
		changeCreditCard(data_change){
			
			this.credit_card = data_change.valid ? data_change.credit_card : null;
		},
		async validCreditCard(data_valid){
			
			this.valid_credit_card = data_valid;
			if( !(this.payment_method === 'credit-card' && this.valid_credit_card ) ) {
				
				this.addNotification({ 
					content: { type: 'request' }, 
					type: 'error', 
					data: { 
						title: 'Error: Credit Card', 
						message: 'The credit card information is not valid',
						app_version: this.$cookies.get('app-version') 
					} 
				});
			}
		},
		formatCurrency(price, totalAmount = null) {
			
			return formatData.currency(price, totalAmount);
		},
		getDiscountPrice(price, discount, quantity){
			
			return calcDiscountPrice(price, discount, quantity);
		},
		validLoadCheckout(balance_customer, pre_credit, expired_days, totalCart, firstOrder, qtyItems, validMoa) {
			
			this.load_checkout = false;
			if ( ( qtyItems > 0 ) && ( totalCart >= 0 ) && ( ( pre_credit + balance_customer ) >= totalCart && expired_days === 0 ) && ( ( firstOrder === null && ( totalCart > 1000 || validMoa ) ) || ( firstOrder != null ) ) ) {
				
				this.load_checkout = true;
				this.processShippingAddress();
			}
			else if ( ( qtyItems > 0 ) && ( totalCart >= 0 ) && ( ( ( pre_credit + balance_customer ) >= totalCart && expired_days === 0 ) || !( ( pre_credit + balance_customer ) >= totalCart && expired_days === 0 ) ) ) {
				
				this.addNotification({ 
					content: { type: 'request' }, 
					type: 'error', 
					data: {	
						title: 'Error: Checkout Order', 
						message: ( ( firstOrder === null ) && !( totalCart > 1000 || validMoa ) ) ? 'The total amount of the order does not meet the condition of the minimum amount required' : 'The balance is not enough and/or you have overdue days', 
						app_version: this.$cookies.get('app-version')
					}
				});
				this.$router.push('/account/dashboard/your-statement');
			}
			else {
				
				this.addNotification({ 
					content: { type: 'request' }, 
					type: 'error', 
					data: {	
						title: 'Error: Checkout Order', 
						message: 'This is strange but I notice that you have no products in your cart', 
						app_version: this.$cookies.get('app-version')
					}
				});
				this.$router.push(this.getUrlBack);
			}
		},
		selectUseBillingAddress(value){
			if( value === 'same' ){
				
				this.billing_address 			= {...this.shipping_address};
				this.update_billing_address		= JSON.parse( JSON.stringify( this.billing_address ) );
			}
			this.select_billing_address_id = this.billing_address.id;
			this.billing_address_type = value;
			this.$root.$emit('change-select-address', { id_form: 'use-billing-address', address: this.billing_address });
		},
		changeBalancePay() {
			
			if (this.use_balance === 'use-balance') {
				
				let total = parseFloat(this.getSubTotalOrder.subtotal) - parseFloat(this.getSubTotalOrder.coupon_discount) + parseFloat(this.getTaxes) + parseFloat(this.select_shipping_method.price);
				
				if( this.original_balance >= 0 && total > this.original_balance ){
					
					this.available_option_pay 	= false;
					this.amount_balance 		= this.original_balance;
				}
				else if( this.original_balance >= 0 ){
					
					this.available_option_pay 	= true;
					this.payment_method 		= 'balance';
					this.amount_balance 		= total;
				}
				else{
					
					this.available_option_pay 	= false;
					this.payment_method 		= 'credit-card';
					this.amount_balance 		= 0;
				}
			}
			else {
				
				this.available_option_pay 	= false;
				this.payment_method 		= 'credit-card';
				this.amount_balance 		= 0;
			}
		},
		updateMethodPay() {
			
			if(this.payment_method !== 'credit-card' ) {
				
				this.$root.$emit('clean-credit-card');
			}
		},
		async processPayOrder(){
			
			this.is_loading_page 	= false;
			this.disabled_checkout 	= false;
			if( this.getterStatusPay?.valid && this.getterStatusPay?.data?.result === 'approved' ){
				
				this.error_checkout = { valid: true };
				await this.getCart();
				window.location.href = this.getterStatusPay.data.url;
			}
			else {
				
				this.error_checkout = { valid: false, title: this.getterStatusPay.data?.title, message: this.getterStatusPay.data?.message };
			}
		},
		async processCreateOrder(){
			
			this.is_loading_page 	= false;
			this.disabled_checkout 	= false;
			if( this.getterCheckoutOrder?.valid ){
				
				this.error_checkout = { valid: true };
				await this.getCart();
				window.location.href = `${ this.$config.main_url }/order/success?status=${ this.getterCheckoutOrder?.data.status }&order=${ this.getterCheckoutOrder?.data.order_name ? this.getterCheckoutOrder?.data.order_name : 'not-found' }`, '_blank';
				
			}
			else{
				
				this.error_checkout = { valid: false, title: this.getterCheckoutOrder?.data?.title, message: this.getterCheckoutOrder?.data?.message };
			}
		},
		getTimeOutOrder(){
			let now_date_minutes 	= moment().get('minutes');
			let now_date_seconds 	= moment().get('seconds');
			let get_timeout 		= 0;
			
			if( ( now_date_minutes === 0 || ( now_date_minutes > 0 && ( now_date_minutes + 1 ) % 2 === 0 ) ) && ( now_date_seconds >= 45 ) ){
				
				get_timeout = ( 60 - now_date_seconds + 5 ) * 1000;
			}
			else if( ( now_date_minutes === 0 || ( now_date_minutes > 0 && now_date_minutes % 2 === 0 ) ) && ( ( now_date_seconds ) <= 10 ) ){
				
				get_timeout = ( now_date_seconds < 3 ? 7 : 5 ) * 1000;
			}
			return get_timeout;
		},
		validPayOrder(){
			if( this.getPaymentMethod === 'credit-card' ){
				
				this.$root.$emit('valid-credit-card-fields');
			}
			if( this.getShippingAddress && this.getBillingAddress && this.getSelectShippingMethod && ( ( this.getPaymentMethod != 'credit-card' ) || ( this.getPaymentMethod === 'credit-card' && this.getValidCreditCard ) ) ){
				
				this.executePayOrder();
			}
			else if( !this.getShippingAddress ){
				
				this.addNotification({ 
					content: { type: 'request' }, 
					type: 'error', 
					data: { title: 'Error: Pay Order', message: 'You need a shipping address', app_version: this.$cookies.get('app-version') } 
				});
			}
			else if( !this.getBillingAddress ){
				
				this.addNotification({ 
					content: { type: 'request' }, 
					type: 'error', 
					data: { title: 'Error: Pay Order', message: 'You need a billing address', app_version: this.$cookies.get('app-version') } 
				});
			}
			else if( !this.getSelectShippingMethod ){
				
				this.addNotification({ 
					content: { type: 'request' }, 
					type: 'error', 
					data: { title: 'Error: Pay Order', message: 'You need a shipping method', app_version: this.$cookies.get('app-version') } 
				});
			}
		},
		async executePayOrder() {
			
			this.is_loading_page 	= true;
			this.disabled_checkout 	= true;
			
			if (this.getterLogin) {
				
				let draft_order_data 		= {
					user_id			: this.getterUser._id,
					affiliate		: null,
					storefront		: null,
					customer		: { 
						_id				: this.getterCustomer._id, 
						shopify_id		: this.getterCustomer.shopify_id, 
						is_dropshipping	: this.getterCustomer.is_dropshipping,
						tax_exempt		: this.getterCustomer.tax_exempt,
						shipping_address: this.shipping_address,
						billing_address : this.billing_address
					},
					products		: this.getterCheckoutLineItems.products,
					shipping_price	: this.select_shipping_method ? this.select_shipping_method.price : 0,
					tax				: this.formatCurrency( this.getTaxes, true ).number,
					subtotal		: this.formatCurrency( this.getSubTotalOrder.subtotal - this.getSubTotalOrder.coupon_discount, true ).number,
					coupon			: this.getterCoupon != null ? this.getterCoupon : null,
					use_balance 	: this.amount_balance,
					total			: this.formatCurrency( this.getTotal, true ).number,
					shipping_info	: { 
						products: this.getterShippingData.products, 
						rates	: this.getterShippingData.rates.filter((item_rate) => item_rate.shipping_type.name === this.select_shipping_method.method ), 
						taxes	: this.getterShippingData.taxes 
					},
					payment_method 	: this.payment_method,
					financial_status: 'pending',
					product_origin	: this.product_origin.indexOf('pre-order') >= 0 ? `${ this.product_origin }-${ this.$route.query.type }` : this.product_origin,
					need_invoice 	: this.need_invoice ? 'invoice' : 'no-invoice',
					note 			: `${ this.order_note } ${ this.need_invoice ? '' : "Automatic Note: Please don't include invoice" }`,
					bundle_id		: this.product_origin.indexOf('pre-order') >= 0 && this.$route.query.type === 'advance' ? this.$route.query.bundle : null,
					preorder_id		: this.product_origin.indexOf('pre-order') >= 0 && this.$route.query.type === 'complete' ? this.$route.query.pre_order : null
				};
				let body_payment_request 	= {
					user_id						: this.getterUser._id,
					customer_id					: draft_order_data.customer._id,
					customer_sh_id				: draft_order_data.customer.shopify_id,
					amount						: draft_order_data.total,
					use_balance 				: draft_order_data.use_balance,
					'payment-method'			: draft_order_data.payment_method,
					'card-number'				: ['credit-card'].includes( this.payment_method ) ? this.$encrypt(this.credit_card.cardNumber) : null,
					'card-date'					: ['credit-card'].includes( this.payment_method ) ? this.$encrypt(`${ parseInt( this.credit_card.cardMonth ) > 9 ? this.credit_card.cardMonth : `0${ this.credit_card.cardMonth }` }/${ this.credit_card.cardYear.toString().slice(-2) }`) : null,
					'card-verification-value'	: ['credit-card'].includes( this.payment_method ) ? this.$encrypt(this.credit_card.cardCvv) : null,
					'card-name'					: ['credit-card'].includes( this.payment_method ) ? this.$encrypt(this.credit_card.cardName) : null,
					'address-line'				: draft_order_data.customer.billing_address.address_1,
					'address-country'			: draft_order_data.customer.billing_address.country,
					'address-state'				: draft_order_data.customer.billing_address.state,
					'address-city'				: draft_order_data.customer.billing_address.city,
					'address-zipcode'			: draft_order_data.customer.billing_address.zip,
					originPayment				: 'Payment Order Cata1og',
					product_origin				: draft_order_data.product_origin,
					code_af						: null,
					store						: null
				};
				let body_checkout_request	= {
					need_validate	: {
						shipping_address: true,
						billing_address	: true
					},
					shipping_address: draft_order_data.customer.shipping_address,
					billing_address	: draft_order_data.customer.billing_address,
					shipping_info	: draft_order_data.shipping_info,
					product_origin	: this.product_origin,
					type_checkout	: this.$route.query.type,
					is_login		: this.getterLogin
				};
				let body_draft_order_request= formatData.draftOrder(draft_order_data, this.preorder_bundle_list, this.preorder_standard_list );
				
				let timeOut 				= this.getTimeOutOrder();
				if ( ['credit-card', 'paypal'].includes(this.payment_method) ) {
					
					setTimeout(async () => {
						
						await this.payOrder({ body_checkout_request, body_draft_order_request, body_payment_request }).then( async (result_pay) => {
							
							await this.processPayOrder();
						}).catch( async (error_pay) => {
							
							await this.processPayOrder();
						});
					}, timeOut);
				} 
				else {
					
					setTimeout(async () => {
						await this.createOrder({ body_checkout_request, body_draft_order_request }).then( async (result_order) => {
							
							await this.processCreateOrder();
						}).catch( async (error_order) => {
							
							await this.processCreateOrder();
						});
						
					}, timeOut);
				}
			}
			else {
				
				this.$router.replace('/account/login');
			}
		}
	}
};
