import { render, staticRenderFns } from "./only-content.vue?vue&type=template&id=9e068bf8"
import script from "./only-content.vue?vue&type=script&lang=js"
export * from "./only-content.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderSimple: require('C:/Users/Pedro Camacaro/Documents/proyectos-2b/cata1og-us-wholesale/store-vue2-main/components/header/header-simple.vue').default,FooterSimple: require('C:/Users/Pedro Camacaro/Documents/proyectos-2b/cata1og-us-wholesale/store-vue2-main/components/footer/footer-simple.vue').default,SidebarAccount: require('C:/Users/Pedro Camacaro/Documents/proyectos-2b/cata1og-us-wholesale/store-vue2-main/components/sidebar/account.vue').default})
