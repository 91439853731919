import { render, staticRenderFns } from "./collection-content.vue?vue&type=template&id=5de03ad5"
import script from "./collection-content.vue?vue&type=script&lang=js"
export * from "./collection-content.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductCollection: require('C:/Users/Pedro Camacaro/Documents/proyectos-2b/cata1og-us-wholesale/store-vue2-main/components/product/product-collection.vue').default,SidebarProduct: require('C:/Users/Pedro Camacaro/Documents/proyectos-2b/cata1og-us-wholesale/store-vue2-main/components/sidebar/product.vue').default})
